/* You can add global styles to this file, and also import other style files */

@import './app/components/modal/modal-animation.scss';


@font-face {
  font-family: Lato-Regular;
  src: url(assets/fonts/lato/Lato-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Lato-Bold";
  src: url(assets/fonts/lato/Lato-Bold.ttf) format("opentype");
}

* {
  font-family: "Lato-Regular";
  --text-primary: #8a6cc8;
  // --bg-primary: #8a6cc8;
  // --text-success: #00cd2d;
  // --bg-success: #00cd2d;
  // --text-dark: #3c3c3c;
  // --bg-dark: #3c3c3c;
  // --text-secondary: #9d9d9d;
  // --bg-secondary: #9d9d9d;
  // --text-error: #ff0000;
  // --bg-error: #ff0000;

  // Primary (Blue)
  --Foundation-Blue-B500: #003E9C;
  --Foundation-Blue-B400: #0047B3;
  --Foundation-Blue-B300: #0071E1;
  --Foundation-Blue-B200: #2B7FFF;
  --Foundation-Blue-B100: #6BA6FF;
  --Foundation-Blue-B75: #96C0FF;
  --Foundation-Blue-B50: #E6F0FF;

  // Grey
  --Foundation-Grey-B500: #141414;
  --Foundation-Grey-B400: #171717;
  --Foundation-Grey-B300: #212121;
  --Foundation-Grey-B200: #474747;
  --Foundation-Grey-B100: #7E7E7E;
  --Foundation-Grey-B75: #A4A4A4;
  --Foundation-Grey-B50: #E9E9E9;
  // Danger (Red)
  --Foundation-Red-B500: #9C0000;
  --Foundation-Red-B400: #B30000;
  --Foundation-Red-B300: #F00;
  --Foundation-Red-B200: #FF2B2B;
  --Foundation-Red-B100: #FF6B6B;
  --Foundation-Red-B75: #FF9696;
  --Foundation-Red-B50: #FFE6E6;
  // Success (Green)
  --Foundation-Green-B500: #18662A;
  --Foundation-Green-B400: #1C7530;
  --Foundation-Green-B300: #28A745;
  --Foundation-Green-B200: #4DB665;
  --Foundation-Green-B100: #82CC93;
  --Foundation-Green-B75: #A7DBB3;
  --Foundation-Green-B50: #EAF6EC;
  // Warning
  --Foundation-Warning-B500: #9C7900;
  --Foundation-Warning-B400: #B38B00;
  --Foundation-Warning-B300: #FFC700;
  --Foundation-Warning-B200: #FFD12B;
  --Foundation-Warning-B100: #FFDF6B;
  --Foundation-Warning-B75: #FFE896;
  --Foundation-Warning-B50: #FFF9E6;

  // Black
  --Neutral-Black: #212121;

  --font-weight-600: 600;
  --font-family: Lato;

  // Desktop
  --desktop-heading-1: 64px;
  --desktop-heading-line-height-1: 76px;

  --desktop-heading-2: 36px;
  --desktop-heading-line-height-2: 44px;

  --desktop-heading-3: 28px;
  --desktop-heading-line-height-3: 36px;

  --desktop-heading-4: 20px;
  --desktop-heading-line-height-4: 28px;

  --desktop-body-1: 18px;
  --desktop-heading-line-height-1: 28px;

  --desktop-body-2: 16px;
  --desktop-heading-line-height-2: 24px;

  --desktop-body-3: 14px;
  --desktop-heading-line-height-3: 20px;


  // Mobile
  --mobile-heading-1: 28px;
  --mobile-heading-line-height-1: 36px;

  --mobile-heading-2: 20px;
  --mobile-heading-line-height-2: 28px;

  --mobile-heading-3: 18px;
  --mobile-heading-line-height-3: 24px;

  --mobile-heading-4: 16px;
  --mobile-heading-line-height-4: 20px;

  --mobile-body-1: 16px;
  --mobile-body-line-height-1: 24px;

  --mobile-body-2: 14px;
  --mobile-body-line-height-2: 20px;

  --mobile-body-3: 12px;
  --mobile-body-line-height-3: 16px;


}
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

html, body{
 min-height: 100%;
 margin: 0px;
}


/* You can add global styles to this file, and also import other style files */

:root {
  /** primary **/
  --color-primary-600: #7052ac;
  --color-primary-500: #8a6cc8;
  --color-primary-400: #a189d3;
  --color-primary-300: #b9a7de;
  --color-primary-200: #d0c4e9;
  --color-primary-100: #e8e2f4;

  /** secondary **/
  --color-secondary-600: #111010;
  --color-secondary-500: #212121;
  --color-secondary-400: #333333;
  --color-secondary-300: #666666;
  --color-secondary-200: #999999;
  --color-secondary-100: #cccccc;

  /** success **/
  --color-success-600: #2b622d;
  --color-success-500: #4caf50;
  --color-success-400: #70bf73;
  --color-success-300: #94cf96;
  --color-success-200: #b7dfb9;
  --color-success-100: #dbefdc;

  /** warning **/
  --color-warning-600: #963e03;
  --color-warning-500: #ff6700;
  --color-warning-400: #ff812b;
  --color-warning-300: #ffa76b;
  --color-warning-200: #ffc196;
  --color-warning-100: #fff0e6;

  /** danger **/
  --color-danger-600: #852020;
  --color-danger-500: #ff3232;
  --color-danger-400: #ff5b5b;
  --color-danger-300: #ff8484;
  --color-danger-200: #ffadad;
  --color-danger-100: #ffd6d6;

  /** natural **/

  --color-natural-500: #ffffff;

  --ff-primary: "DM Sans", sans-serif;

  --ff-body: var(--ff-primary);
  --ff-label: var(--ff-primary);
  --ff-heading: var(--ff-primary);

  --fw-bold: 700;
  --fw-semi-bold: 500;
  --fw-regular: 400;

  --fs-50: 0.512rem;
  --fs-100: 0.64rem; //10.24px
  --fs-150: 0.8rem; //12.8px
  --fs-200: 1rem; //16px
  --fs-250: 1.25rem; //20px
  --fs-300: 1.563rem; //25px
  --fs-350: 1.953rem;
  --fs-400: 2.441rem;
  --fs-450: 3.052rem;
  --fs-500: 3.815rem;
  --fs-550: 4.768rem;

  --mg-50: 0.25rem --gap1: 0.25rem; /* 4px */
  --gap2: 0.5rem; /* 8px */
  --gap3: 1rem; /* 16px */
  --gap4: 1.5rem; /* 24px */
  --gap5: 2rem; /* 32px */
  --gap6: 3rem; /* 48px */
  --gap7: 4rem; /* 64px */
  --gap8: 5rem; /* 80px */
  --gap9: 6rem; /* 96px */
  --gap10: 8rem; /* 128px */
  /* margin*/
  --m1: 0.25rem;
  --m2: 0.5rem;
  --m3: 0.75rem;
  --m4: 1rem;
  --m5: 1.25rem;
  --m6: 1.5rem;
  --m7: 1.75rem;
  --m8: 2rem;
  --m9: 2.25rem;
  --m10: 2.5rem;
  /* padding*/
  --p1: 0.25rem;
  --p2: 0.5rem;
  --p3: 0.75rem;
  --p4: 1rem;
  --p5: 1.25rem;
  --p6: 1.5rem;
  --p7: 1.75rem;
  --p8: 2rem;
  --p9: 2.25rem;
  --p10: 2.5rem;

  /* Width Utilities */
  --w-5: 5%;
  --w-10: 10%;
  --w-15: 15%;
  --w-20: 20%;
  --w-25: 25%;
  --w-30: 30%;
  --w-35: 35%;
  --w-40: 40%;
  --w-45: 45%;
  --w-50: 50%;
  --w-55: 55%;
  --w-60: 60%;
  --w-65: 65%;
  --w-70: 70%;
  --w-75: 75%;
  --w-80: 80%;
  --w-85: 85%;
  --w-90: 90%;
  --w-95: 95%;
  --w-100: 100%;
  /* Height Utilities */
  --h-5: 5%;
  --h-10: 10%;
  --h-15: 15%;
  --h-20: 20%;
  --h-25: 25%;
  --h-30: 30%;
  --h-35: 35%;
  --h-40: 40%;
  --h-45: 45%;
  --h-50: 50%;
  --h-55: 55%;
  --h-60: 60%;
  --h-65: 65%;
  --h-70: 70%;
  --h-75: 75%;
  --h-80: 80%;
  --h-85: 85%;
  --h-90: 90%;
  --h-95: 95%;
  --h-100: 100%;
}


//Side bar design

.row-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.single-row-box {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

//Utilities
.Secondary-text-200 {
  color: var(--color-secondary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Small-secondary-text-200 {
  color: var(--color-secondary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}
.Bold-secondary-text-200 {
  color: var(--color-secondary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Small-secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Regular-secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;
}
.Big-secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;
}
.Bold-secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Secondary-text-400 {
  color: var(--color-secondary-400);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Bold-secondary-text-400 {
  color: var(--color-secondary-400);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}
.Small-Secondary-header-500 {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Secondary-header-500 {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Medium-secondary-header-500 {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-200);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Bold-secondary-header-500 {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-300);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}
.Primary-text-200 {
  color: var(--color-primary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Primary-text-500 {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Bold-primary-text-500 {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}
.Small-primary-text-400 {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Primary-text-400 {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Neutral-text-500 {
  color: var(--color-natural-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Bold-Neutral-text-500 {
  color: var(--color-natural-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.In-progress-text {
  color: #fbbc05;
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Resolved-text {
  color: #4caf50;
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}
.Open-text {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Assignee-name-text {
  color: var(--color-natural-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Small-Assignee-name-text {
  color: var(--color-natural-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Details-header {
  color: #000;
  font-family: var(--ff-primary);
  font-size: var(--fs-200);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Modal-header-text {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Complaint-status-box {
  display: flex;
  width: 82.336px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

// ...................................................
// justifying content start here
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
// justifying content end here

// align items start here
.items-center {
  align-items: center;
}
.items-end {
  align-items: end;
}
.items-flex-end {
  align-items: flex-end;
}
.items-flex-start {
  align-items: flex-start;
}
.items-start {
  align-items: start;
}
// end here
// gap start here
.gap-1 {
  gap: 1px;
}
.gap-2 {
  gap: 2px;
}
.gap-3 {
  gap: 3px;
}
.gap-4 {
  gap: 4px;
}
.gap-5 {
  gap: 5px;
}
.gap-6 {
  gap: 6px;
}
.gap-7 {
  gap: 7px;
}
.gap-8 {
  gap: 8px;
}
.gap-9 {
  gap: 9px;
}
.gap-10 {
  gap: 10px;
}
// end here

// positio start here
.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.static {
  position: static;
}
.sticky {
  position: sticky;
}

.fixed {
  position: fixed;
}
// end here

.shadow-box {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.col {
  flex-direction: column;
}
.col {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}

.h-100 {
  height: 100%;
}
.h-75 {
  height: 75%;
}
.h-50 {
  height: 50%;
}
.h-25 {
  height: 25%;
}
.h-10 {
  height: 10%;
}

.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}
.w-10 {
  width: 10%;
}

.auto {
  margin: auto;
}

.mr-1 {
  margin-right: var(--mg-50);
}
// ..................................


.pointer{
  cursor: pointer;
}
.bg-primary-500 {
  background-color: var(--color-primary-500);
}
.bg-primary-400 {
  background-color: var(--color-primary-400);
}
.bg-primary-300 {
  background-color: var(--color-primary-300);
}
.bg-primary-200 {
  background-color: var(--color-primary-200);
}
.bg-primary-100 {
  background-color: var(--color-primary-100);
}

/** secondary **/
.bg-secondary-500 {
  background-color: var(--color-secondary-500);
}
.bg-secondary-400 {
  background-color: var(--color-secondary-400);
}
.bg-secondary-300 {
  background-color: var(--color-secondary-300);
}
.bg-secondary-200 {
  background-color: var(--color-secondary-200);
}
.bg-secondary-100 {
  background-color: var(--color-secondary-100);
}
/** success **/
.bg-success-500 {
  background-color: var(--color-success-500);
}
.bg-success-400 {
  background-color: var(--color-success-400);
}
.bg-success-300 {
  background-color: var(--color-success-300);
}
.bg-success-200 {
  background-color: var(--color-success-200);
}
.bg-success-100 {
  background-color: var(--color-success-100);
}

/** warning **/
.bg-warning-500 {
  background-color: var(--color-warning-500);
}
.bg-warning-400 {
  background-color: var(--color-warning-400);
}
.bg-warning-300 {
  background-color: var(--color-warning-300);
}
.bg-warning-200 {
  background-color: var(--color-warning-200);
}
.bg-warning-100 {
  background-color: var(--color-warning-100);
}
/** danger **/
.bg-danger-500 {
  background-color: var(--color-danger-500);
}
.bg-danger-400 {
  background-color: var(--color-danger-400);
}
.bg-danger-300 {
  background-color: var(--color-danger-300);
}
.bg-danger-200 {
  background-color: var(--color-danger-200);
}
.bg-danger-100 {
  background-color: var(--color-danger-100);
}
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}
.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
/* Text Justification Utilities */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.overflow-auto {
  overflow-y: auto;
}
/* Justify Content Utilities */

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}
/* Align Items Utilities */

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/* Display Utilities */

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}
.flex-coluum {
  flex-direction: column;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.hidden {
  display: none;
}

/* Flexbox Gap Utilities */

.gap-1 {
  gap: var(--gap1); /* 4px */
}

.gap-2 {
  gap: var(--gap2); /* 8px */
}

.gap-3 {
  gap: var(--gap3); /* 16px */
}

.gap-4 {
  gap: var(--gap4); /* 24px */
}

.gap-5 {
  gap: var(--gap5); /* 32px */
}

.gap-6 {
  gap: var(--gap6); /* 48px */
}

.gap-7 {
  gap: var(--gap7); /* 64px */
}

.gap-8 {
  gap: var(--gap8); /* 80px */
}

.gap-9 {
  gap: var(--gap9); /* 96px */
}

.gap-10 {
  gap: var(--gap10); /* 128px */
}

/* Font Weight Utilities */

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: normal; /* Equivalent to font-weight: 400; */
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: bold; /* Equivalent to font-weight: 700; */
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}
/* Padding Utilities */

/* Padding all sides */
.p-1 {
  padding: var(--p1); /* Adjust as needed */
}

.p-2 {
  padding: var(--p2); /* Adjust as needed */
}

.p-3 {
  padding: var(--p3); /* Adjust as needed */
}

.p-4 {
  padding: var(--p4); /* Adjust as needed */
}

.p-5 {
  padding: var(--p5); /* Adjust as needed */
}

.p-6 {
  padding: var(--p6); /* Adjust as needed */
}

.p-7 {
  padding: var(--p7); /* Adjust as needed */
}

.p-8 {
  padding: var(--p8); /* Adjust as needed */
}

.p-9 {
  padding: var(--p9); /* Adjust as needed */
}

.p-10 {
  padding: var(--p10); /* Adjust as needed */
}

/* Vertical and Horizontal Padding */

/* Vertical padding */
.py-1 {
  padding-top: var(--p1);
  padding-bottom: var(--p1);
}

.py-2 {
  padding-top: var(--p2);
  padding-bottom: var(--p2);
}

.py-3 {
  padding-top: var(--p3);
  padding-bottom: var(--p3);
}

.py-4 {
  padding-top: var(--p4);
  padding-bottom: var(--p4);
}

.py-5 {
  padding-top: var(--p5);
  padding-bottom: var(--p5);
}

.py-6 {
  padding-top: var(--p6);
  padding-bottom: var(--p6);
}

.py-7 {
  padding-top: var(--p7);
  padding-bottom: var(--p7);
}

.py-8 {
  padding-top: var(--p8);
  padding-bottom: var(--p8);
}

.py-9 {
  padding-top: var(--p9);
  padding-bottom: var(--p9);
}

.py-10 {
  padding-top: var(--p10);
  padding-bottom: var(--p10);
}

/* Horizontal padding */
.px-1 {
  padding-left: var(--p1);
  padding-right: var(--p1);
}

.px-2 {
  padding-left: var(--p2);
  padding-right: var(--p2);
}

.px-3 {
  padding-left: var(--p3);
  padding-right: var(--p3);
}

.px-4 {
  padding-left: var(--p4);
  padding-right: var(--p4);
}

.px-5 {
  padding-left: var(--p5);
  padding-right: var(--p5);
}

.px-6 {
  padding-left: var(--p6);
  padding-right: var(--p6);
}

.px-7 {
  padding-left: var(--p7);
  padding-right: var(--p7);
}

.px-8 {
  padding-left: var(--p8);
  padding-right: var(--p8);
}

.px-9 {
  padding-left: var(--p9);
  padding-right: var(--p9);
}

.px-10 {
  padding-left: var(--p10);
  padding-right: var(--p10);
}

/* Individual Padding Sides */

/* Top padding */
.pt-1 {
  padding-top: var(--p1);
}

.pt-2 {
  padding-top: var(--p2);
}

.pt-3 {
  padding-top: var(--p3);
}

.pt-4 {
  padding-top: var(--p4);
}

.pt-5 {
  padding-top: var(--p5);
}

.pt-6 {
  padding-top: var(--p6);
}

.pt-7 {
  padding-top: var(--p7);
}

.pt-8 {
  padding-top: var(--p8);
}

.pt-9 {
  padding-top: var(--p9);
}

.pt-10 {
  padding-top: var(--p10);
}

/* Right padding */
.pr-1 {
  padding-right: var(--p1);
}

.pr-2 {
  padding-right: var(--p2);
}

.pr-3 {
  padding-right: var(--p3);
}

.pr-4 {
  padding-right: var(--p4);
}

.pr-5 {
  padding-right: var(--p5);
}

.pr-6 {
  padding-right: var(--p6);
}

.pr-7 {
  padding-right: var(--p7);
}

.pr-8 {
  padding-right: var(--p8);
}

.pr-9 {
  padding-right: var(--p9);
}

.pr-10 {
  padding-right: var(--p10);
}

/* Bottom padding */
.pb-1 {
  padding-bottom: var(--p1);
}

.pb-2 {
  padding-bottom: var(--p2);
}

.pb-3 {
  padding-bottom: var(--p3);
}

.pb-4 {
  padding-bottom: var(--p4);
}

.pb-5 {
  padding-bottom: var(--p5);
}

.pb-6 {
  padding-bottom: var(--p6);
}

.pb-7 {
  padding-bottom: var(--p7);
}

.pb-8 {
  padding-bottom: var(--p8);
}

.pb-9 {
  padding-bottom: var(--p9);
}

.pb-10 {
  padding-bottom: var(--p10);
}

/* Left padding */
.pl-1 {
  padding-left: var(--p1);
}

.pl-2 {
  padding-left: var(--p2);
}

.pl-3 {
  padding-left: var(--p3);
}

.pl-4 {
  padding-left: var(--p4);
}

.pl-5 {
  padding-left: var(--p5);
}

.pl-6 {
  padding-left: var(--p6);
}

.pl-7 {
  padding-left: var(--p7);
}

.pl-8 {
  padding-left: var(--p8);
}

.pl-9 {
  padding-left: var(--p9);
}

.pl-10 {
  padding-left: var(--p10);
}

/* Margin Utilities */

/* Margin all sides */
.m-1 {
  margin: var(--m1);
}

.m-2 {
  margin: var(--m2);
}

.m-3 {
  margin: var(--m3);
}

.m-4 {
  margin: var(--m4);
}

.m-5 {
  margin: var(--m5);
}

.m-6 {
  margin: var(--m6);
}

.m-7 {
  margin: var(--m7);
}

.m-8 {
  margin: var(--m8);
}

.m-9 {
  margin: var(--m9);
}

.m-10 {
  margin: var(--m10);
}

/* Vertical and Horizontal Margin */

/* Vertical margin */
.my-1 {
  margin-top: var(--m1);
  margin-bottom: var(--m1);
}
.my-2 {
  margin-top: var(--m2);
  margin-bottom: var(--m2);
}
.my-3 {
  margin-top: var(--m3);
  margin-bottom: var(--m3);
}
.my-4 {
  margin-top: var(--m4);
  margin-bottom: var(--m4);
}
.my-5 {
  margin-top: var(--m5);
  margin-bottom: var(--m5);
}
.my-6 {
  margin-top: var(--m6);
  margin-bottom: var(--m6);
}
.my-7 {
  margin-top: var(--m7);
  margin-bottom: var(--m7);
}
.my-8 {
  margin-top: var(--m8);
  margin-bottom: var(--m8);
}
.my-9 {
  margin-top: var(--m9);
  margin-bottom: var(--m9);
}
.my-10 {
  margin-top: var(--m10);
  margin-bottom: var(--m10);
}
/* Horizontal margin */
.mx-1 {
  margin-left: var(--m1);
  margin-right: var(--m1);
}
.mx-2 {
  margin-left: var(--m2);
  margin-right: var(--m2);
}
.mx-3 {
  margin-left: var(--m3);
  margin-right: var(--m3);
}
.mx-4 {
  margin-left: var(--m4);
  margin-right: var(--m4);
}
.mx-5 {
  margin-left: var(--m5);
  margin-right: var(--m5);
}
.mx-6 {
  margin-left: var(--m6);
  margin-right: var(--m6);
}
.mx-7 {
  margin-left: var(--m7);
  margin-right: var(--m7);
}
.mx-8 {
  margin-left: var(--m8);
  margin-right: var(--m8);
}
.mx-9 {
  margin-left: var(--m9);
  margin-right: var(--m9);
}
.mx-10 {
  margin-left: var(--m10);
  margin-right: var(--m10);
}
/* Individual Margin Sides */

/* Top margin */
.mt-1 {
  margin-top: var(--m1);
}

.mt-2 {
  margin-top: var(--m2);
}

.mt-3 {
  margin-top: var(--m3);
}

.mt-4 {
  margin-top: var(--m4);
}

.mt-5 {
  margin-top: var(--m5);
}

.mt-6 {
  margin-top: var(--m6);
}

.mt-7 {
  margin-top: var(--m7);
}

.mt-8 {
  margin-top: var(--m8);
}

.mt-9 {
  margin-top: var(--m9);
}

.mt-10 {
  margin-top: var(--m10);
}

/* Right margin */

.mr-1 {
  margin-right: var(--m1);
}

.mr-2 {
  margin-right: var(--m2);
}

.mr-3 {
  margin-right: var(--m3);
}

.mr-4 {
  margin-right: var(--m4);
}

.mr-5 {
  margin-right: var(--m5);
}

.mr-6 {
  margin-right: var(--m6);
}

.mr-7 {
  margin-right: var(--m7);
}

.mr-8 {
  margin-right: var(--m8);
}

.mr-9 {
  margin-right: var(--m9);
}

.mr-10 {
  margin-right: var(--m10);
}

/* Bottom margin */
.mb-1 {
  margin-bottom: var(--m1);
}

.mb-2 {
  margin-bottom: var(--m2);
}

.mb-3 {
  margin-bottom: var(--m3);
}

.mb-4 {
  margin-bottom: var(--m4);
}

.mb-5 {
  margin-bottom: var(--m5);
}

.mb-6 {
  margin-bottom: var(--m6);
}

.mb-7 {
  margin-bottom: var(--m7);
}

.mb-8 {
  margin-bottom: var(--m8);
}

.mb-9 {
  margin-bottom: var(--m9);
}

.mb-10 {
  margin-bottom: var(--m10);
}

/* Left margin */
.ml-1 {
  margin-left: var(--m1);
}

.ml-2 {
  margin-left: var(--m2);
}

.ml-3 {
  margin-left: var(--m3);
}

.ml-4 {
  margin-left: var(--m4);
}

.ml-5 {
  margin-left: var(--m5);
}

.ml-6 {
  margin-left: var(--m6);
}

.ml-7 {
  margin-left: var(--m7);
}

.ml-8 {
  margin-left: var(--m8);
}

.ml-9 {
  margin-left: var(--m9);
}

.ml-10 {
  margin-left: var(--m10);
}

/* Width Utilities */

.w-5 {
  width: var(--w-5);
}

.w-10 {
  width: var(--w-10);
}

.w-15 {
  width: var(--w-15);
}

.w-20 {
  width: var(--w-20);
}

.w-25 {
  width: var(--w-25);
}

.w-30 {
  width: var(--w-30);
}

.w-35 {
  width: var(--w-35);
}

.w-40 {
  width: var(--w-40);
}

.w-45 {
  width: var(--w-45);
}

.w-50 {
  width: var(--w-50);
}

.w-55 {
  width: var(--w-55);
}

.w-60 {
  width: var(--w-60);
}

.w-65 {
  width: var(--w-65);
}

.w-70 {
  width: var(--w-70);
}

.w-75 {
  width: var(--h-75);
}

.w-80 {
  width: var(--w-80);
}

.w-85 {
  width: var(--h-85);
}

.w-90 {
  width: var(--w-90);
}

.w-95 {
  width: var(--w-95);
}

.w-100 {
  width: var(--w-100);
}
.w-vw {
  width: 100vw;
}

/* Height Utilities */

.h-5 {
  height: var(--h-5);
}
.h-10 {
  height: var(--h-10);
}

.h-15 {
  height: var(--h-15);
}

.h-20 {
  height: var(--h-20);
}

.h-25 {
  height: var(--h-25);
}

.h-30 {
  height: var(--h-30);
}

.h-35 {
  height: var(--h-35);
}

.h-40 {
  height: var(--h-40);
}

.h-45 {
  height: var(--h-45);
}

.h-50 {
  height: var(--h-50);
}
.h-55 {
  height: var(--h-55);
}

.h-60 {
  height: var(--h-60);
}

.h-65 {
  height: var(--h-65);
}

.h-70 {
  height: var(--h-70);
}

.h-75 {
  height: var(--h-75);
}

.h-80 {
  height: var(--h-80);
}

.h-85 {
  height: var(--h-85);
}

.h-90 {
  height: var(--h-90);
}

.h-95 {
  height: var(--h-95);
}

.h-100 {
  height: var(--h-100);
}
.h-vh {
  height: 100vh;
}

.text-white {
  color: #ffffff;
}
.text-black {
  color: #212121;
}
.text-black {
  color: #852020;
}

/* Position Utilities */

/* Static Position */
.position-static {
  position: static;
}

/* Relative Position */
.position-relative {
  position: relative;
}

/* Absolute Position */
.position-absolute {
  position: absolute;
}

/* Fixed Position */
.position-fixed {
  position: fixed;
}

/* Sticky Position */
.position-sticky {
  position: sticky;
  top: 0;
}

/* Box Shadow Utilities */

/* Small shadow */
.shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Regular shadow */
.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Large shadow */
.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Extra large shadow */
.shadow-xl {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Inner shadow */
.shadow-inner {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}


          .txt-body {
            color: var(--Secondary-300, #666);

            /* Small Text */

            font-size: 12.8px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

        .company-card-active {
          border: 1px solid #8a6cc8;
          color: #8a6cc8;
        }
        .image-height {
          height: 60px !important;
          width: auto;
          margin-top: 20px;
        }
        .initial-name {
          color: #000;
          font-size: 30px;
          margin-top: 28px;
        }
        .main-body-overflow {
          height: 100%;
          overflow-y: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none;
        }
        .main-body-overflow::-webkit-scrollbar {
          display: none;
        }
        .main-body-form {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 16px;
          width: 100%;

          .custom-name {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0px;
            width: 100%;
            gap: 8px;

            .customInput {
              width: 50%;
            }
          }
          a {
            color: #8a6cc8;
            text-decoration: none;
            cursor: pointer;
            margin-top: -10px;
            width: 100%;
            text-align: end;
            font-size: 12.8px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .hint-div {
            display: flex;
            flex-direction: column;
            padding: 12px 16px;
            gap: 10px;
            border-radius: 5px;
            background: #f6f6f6;

            .hints {
              color: var(--Secondary-300, #666);
              font-size: 12.8px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-left: 12px;

              .change-email {
                color: var(--Primary-500, #8a6cc8);
                font-size: 12.8px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration: none;
              }
            }
          }
       }