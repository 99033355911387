@keyframes enter-slide-down {
    from {
      // transform: translate(-50%, -60%);
      top: 50%;
    }
    to {
      // transform: translate(-50%, -50%);
      top: 150%;
    }
  }
  @keyframes enter-slide-up {
    from {
      // transform: translate(-50%, -60%);
      top: 100%;
      // bottom: -1500px;
    }
    to {
      // transform: translate(-50%, -50%);
      top: 50%;
      // bottom: auto;
    }
  }
  
  @keyframes enter-scaling {
    from {
      transform: scale(0.3) translate(-50%, -50%);
    }
    to {
      transform: scale(1) translate(-50%, -50%);
    }
  }
  
  @keyframes leave-scaling {
    from {
      transform: scale(1) translate(-50%, -50%);
    }
    to {
      transform: scale(0) translate(-50%, -50%);
    }
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes overlay-leave {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      z-index: 0;
      position: static;
    }
  }